import React, { useState, useEffect } from "react";
import usersService from "../services/usersService";

export default function Player1form(props) {
  const { values, errors } = props;
  const [player1Details, setPlayer1Details] = useState({ Player1Firstname: '', Player1Lastname: '', Player1Email: '', Player1Telephone: '', Player1Telephone: '' });

  const token = localStorage.getItem("token");
  //const username = JSON.parse(localStorage.getItem('userData')).email;
  let userData = localStorage.getItem("userData");
  if (userData) {
    userData = JSON.parse(userData);
  }
  const [hidepwdblock, setHidePwdBlock] = useState(false);

  const updatePlayer1Data = (e) => {
    if (e.target.name == "Player1Telephone") {
      values[e.target.name] = e.target.value
        .replace(/[^\w\s]/gi, "")
        .replace(/[^0-9]/g, "");
      e.target.value = values[e.target.name];
    } else {
      values[e.target.name] = e.target.value;
    }
    setPlayer1Details({
      ...player1Details,
      [e.target.name]: values[e.target.name],
    });
  };

  useEffect(() => {
    if (token != null && userData != null && token != "" && userData != "") {
      usersService
        .getUserDetailsByUsername(userData.email)
        .then((response) => {
          if (response.hasOwnProperty("data")) {
            if (response.data.length > 0) {
              // If user exist do not create user
              setHidePwdBlock(true);
              let p_data = {};
              values.Player1Firstname = p_data.Player1Firstname =
                response.data[0].FirstName;

              values.Player1Lastname = p_data.Player1Lastname =
                response.data[0].LastName;

              values.Player1Email = p_data.Player1Email =
                response.data[0].email;

              values.Player1Telephone = p_data.Player1Telephone =
                response.data[0].MobileNumber;
              setPlayer1Details(p_data);
            }
          }
        })
        .catch((error) => {
          console.log("Check User error : ", error);
        });
    }

    if (values) {
      let player1Data = {};
      if (values.Player1Firstname) {
        player1Data.Player1Firstname = values.Player1Firstname;
      }
      if (values.Player1Lastname) {
        player1Data.Player1Lastname = values.Player1Lastname;
      }
      if (values.Player1Email) {
        player1Data.Player1Email = values.Player1Email;
      }
      if (values.Player1Telephone) {
        player1Data.Player1Telephone = values.Player1Telephone;
      }
      setPlayer1Details(player1Data);
    }
  }, []);

  return (
    <div className="grid grid-cols-1 py-4 lg:py-8">
      <form
        method="post"
        autoComplete="off"
        className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:px-[40px]"
      >
        <label className="block">
          <span className="text-[#939393] font-medium text-[18px]">
            First Name*
          </span>
          <input
            type="text"
            className="mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
            id="Player1Firstname"
            name="Player1Firstname"
            value={player1Details.Player1Firstname || ''}
            onChange={updatePlayer1Data}
          />
          <div className="text-right text-red-500 text-xs mt-1 italic first-letter:capitalize">
            {errors.Player1Firstname?.message}
          </div>
        </label>
        <label className="block">
          <span className="text-[#939393] font-medium text-[18px]">
            Last Name*
          </span>
          <input
            type="text"
            className="mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
            id="Player1Lastname"
            name="Player1Lastname"
            value={player1Details.Player1Lastname || ''}
            onChange={updatePlayer1Data}
          />
          <div className="text-right text-red-500 text-xs mt-1 italic first-letter:capitalize">
            {errors.Player1Lastname?.message}
          </div>
        </label>
        <label className="block">
          <span className="text-[#939393] font-medium text-[18px]">
            Email address*
          </span>
          <input
            type="email"
            className="mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
            id="Player1Email"
            name="Player1Email"
            value={player1Details.Player1Email || ''}
            onChange={updatePlayer1Data}
          />
          <div className="text-right text-red-500 text-xs mt-1 italic first-letter:capitalize">
            {errors.Player1Email?.message}
          </div>
        </label>
        <label className="block">
          <span className="text-[#939393] font-medium text-[18px]">
            Phone Number*
          </span>
          <input
            type="tel"
            className="mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
            id="Player1Telephone"
            name="Player1Telephone"
            value={player1Details.Player1Telephone || ''}
            onChange={updatePlayer1Data}
          />
          <div className="text-right text-red-500 text-xs mt-1 italic first-letter:capitalize">
            {errors.Player1Telephone?.message}
          </div>
        </label>
        {hidepwdblock ? null : (
          <label className="block" id="pwd-block">
            <h2 className="text-[#707070] font-semibold text-[18px]">
              ACCOUNT DETAILS
            </h2>
            <span className="text-[#707070] font-medium text-[15px]">
              Your account will be created using the below password if you are
              not registered with us.
            </span>
            <br />
            <br />
            <span className="text-[#939393] font-medium text-[18px]">
              Your Password*
            </span>
            <input
              type="password"
              className="mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
              id="Player1Password"
              name="Player1Password"
              value={player1Details.Player1Password || ''}
              onChange={updatePlayer1Data}
            />
            <div className="text-right text-red-500 text-xs mt-1 italic first-letter:capitalize">
              {errors.Player1Password?.message}
            </div>
          </label>
        )}
      </form>
    </div>
  );
}
