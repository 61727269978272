import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import toast from "react-hot-toast";
import TimeRangeSlider from "react-time-range-slider";
import axios from "axios";
import "../styles/css/Global-Style.css";
import "../styles/css/Pick_a_date.css";
import bookingService from "../services/bookingsService/index";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function PickDate(props) {
  let now = new Date();
  var dateString = moment(now).format("yyyy-MM-DD");
  //var start_of_web = moment("2022-04-01");
  var minDate = moment(now).format("yyyy-MM-DD");
  /* if (moment(now).diff(start_of_web, "days") < 0) {
    minDate = moment("2022-04-01").format("yyyy-MM-DD");
  } */
  var maxDate = moment(now).add(7, "days").format("yyyy-MM-DD");
  const [datevalue, setDateValue] = useState(dateString);
  const [minDateValue, setMinDateValue] = useState(minDate);
  const [maxDateValue, setMaxDateValue] = useState(maxDate);
  const [timerangevalue, setTimeRangeValue] = useState({
    start: "06:00",
    end: "20:00",
  });
  const [defaultTimeRange, setDefaultTimeRange] = useState({
    start: "06:00",
    end: "20:00",
  });

  const ref = React.createRef();
  const DateCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <button className="date-custom-input" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  const [player, setPlayerValue] = useState("");
  const [rate, setRateValue] = useState("");
  const [errormsg, setErrorMsg] = useState("");
  const [allSlots, setAllSlots] = useState([]);
  const [loadingSlots, setLoadingSlots] = useState(false);
  const [existingusermembership, setExistingUserMembership] = useState(false);
  const [usermembershipData, setUsermembershipData] = useState();
  const [bookingAllowed, setBookingAllowed] = useState(true);
  const [isFMember, setIsFMember] = useState(true);
  const { nextStep, values, setFormData } = props;
  let teeTimeHeading = "";
  let previousSlot = {};
  const token = localStorage.getItem("token");
  let username = "";
  if (token) {
    username = JSON.parse(localStorage.getItem("userData")).email;
  }
  values.hasUserMembership = false;

  const changeCompleteHandler = (time) => {

    let start_time = time.start.hours + ":" + time.start.minutes;
    let end_time = time.end.hours + ":" + time.end.minutes;
    if (start_time.length == 4) {
      start_time = "0" + start_time;
    }
    if (end_time.length == 4) {
      end_time = "0" + end_time;
    }

    setTimeRangeValue({ start: start_time, end: end_time });

    let selectedDay = moment(datevalue).format("dddd");
    //if (moment(datevalue).diff(start_of_web, "days") >= 0) {
    if (usermembershipData.attributes[selectedDay]) {
      getTimeSlotData("", start_time, end_time);
    } else {
      setAllSlots([]);
    }
    /* } else {
      setAllSlots([]);
    } */
  };

  const timeChangeHandler = (time) => {
    setTimeRangeValue(time);
  };

  const dateChangeHandler = (dateval) => {
    setDateValue(dateval);
    let selectedDay = moment(dateval).format("dddd");
    //if (moment(dateval).diff(start_of_web, "days") >= 0) {
    if (usermembershipData && usermembershipData.hasOwnProperty("attributes")) {
      if (usermembershipData.attributes[selectedDay]) {
        getTimeSlotData(dateval);
      } else {
        setAllSlots([]);
      }
    } else {
      setAllSlots([]);
    }

    /* } else {
      setAllSlots([]);
    } */
  };

  const storePlayerAndRate = (
    playercount,
    playerrate,
    slotstarttime,
    selectedslotid
  ) => {
    setPlayerValue(playercount);
    setRateValue(playerrate);
    values.date = datevalue;
    values.time = timerangevalue;
    values.start_time = timerangevalue.start;
    values.end_time = timerangevalue.end;
    values.selectedslot = slotstarttime;
    values.Player_Count = playercount;
    values.Player_Rate = playerrate;
    values.SelectedSlotId = selectedslotid;
    nextStep();
  };

  function storePlayerAndRateFun(
    playercount,
    playerrate,
    slotstarttime,
    selectedslotid,
    maxPlayers,
    expiry_secs
  ) {
    setPlayerValue(playercount);
    setRateValue(playerrate);
    values.date = datevalue;
    values.time = timerangevalue;
    values.start_time = timerangevalue.start;
    values.end_time = timerangevalue.end;
    values.selectedslot = slotstarttime;
    values.Player_Count = playercount;
    values.Player_Rate = playerrate;
    values.SelectedSlotId = selectedslotid;
    values.hasUserMembership = existingusermembership;
    values.isFMember = isFMember;
    values.maxPlayers = maxPlayers;
    values.expiry_secs = expiry_secs;
    setFormData(values);
    console.log(
      "Going to Personal Info " +
      JSON.stringify({
        AllDATA: values,
      })
    );
    nextStep();
  }

  async function getUserMembershipData(userEmail) {
    const qs = require("qs");
    const query = qs.stringify(
      {
        populate: "UserID,MembershipID",
        filters: {
          UserID: {
            email: {
              $eq: userEmail,
            },
          },
          Active: true,
        },
      },
      {
        encodeValuesOnly: true,
      }
    );
    await axios
      .get(process.env.REACT_APP_API_URL + "user-memberships/?" + query, {
        headers: { Authorization: process.env.REACT_APP_API_KEY },
      })
      .then((response) => {
        const usermembershipdatas = response.data.data[0];
        if (typeof usermembershipdatas != "undefined") {
          setExistingUserMembership(true);
          values.hasUserMembership = true;
          if (
            !usermembershipdatas.attributes.MembershipID.IsDefault &&
            !usermembershipdatas.attributes.MembershipID.IsMemberGuest
          ) {
            setIsFMember(false);
            values.isFMember = false;
          }
          //Advance Days Logic
          setUsermembershipData(
            usermembershipdatas.attributes.MembershipID.data
          );
          let membershipData = usermembershipdatas.attributes.MembershipID;
          let remainingDays = moment(
            usermembershipdatas.attributes.EndDate
          ).diff(moment(now), "days");
          console.log("Remaining Days- " + remainingDays);
          if (remainingDays >= 0) {
            if (membershipData.hasOwnProperty("data")) {
              if (membershipData.data.hasOwnProperty("attributes")) {
                let noOfAdvanceDays =
                  parseInt(membershipData.data.attributes.AdvanceDays) - 1;
                let maxDate = moment(now)
                  .add(noOfAdvanceDays, "days")
                  .format("yyyy-MM-DD");

                if (remainingDays <= 0) {
                  maxDate = moment(
                    usermembershipdatas.attributes.EndDate
                  ).format("yyyy-MM-DD");
                  console.log("Max Date - " + maxDate);
                  setMaxDateValue(maxDate);
                } else if (
                  remainingDays >=
                  parseInt(membershipData.data.attributes.AdvanceDays) - 1
                ) {
                  setMaxDateValue(maxDate);
                }

                let start_time = moment(
                  maxDate + "T" + membershipData.data.attributes.StartTime
                ).format("HH:mm");
                let end_time = moment(
                  maxDate + "T" + membershipData.data.attributes.EndTime
                ).format("HH:mm");
                setDefaultTimeRange({ start: start_time, end: end_time });
                setTimeRangeValue({ start: start_time, end: end_time });
              }
            }
          } else {
            maxDate = moment(usermembershipdatas.attributes.EndDate).format(
              "yyyy-MM-DD"
            );
            setMaxDateValue(maxDate);
          }
          //console.log("UserMembership Data : ", usermembershipdatas);
        }
      })
      .catch((error) => {
        console.log(
          "An error has occured in function getMembership : ",
          error
        );
        //setErrorMsg("Some error has occured.");
      });
  }

  useEffect(() => {
    console.info("User On Pick a date");
    if (token && username) {
      console.info("Username", { user: username });
      getUserMembershipData(username);
    } else {
      console.info("User is not loggedin");
      bookingService.getDefaultMembership().then((result) => {
        let actual_response = result.data.data;
        if (actual_response.length > 0) {
          let noOfAdvanceDays =
            parseInt(actual_response[0].attributes.AdvanceDays) - 1;
          let maxDate = moment()
            .add(noOfAdvanceDays, "days")
            .format("yyyy-MM-DD");
          setMaxDateValue(maxDate);
          setUsermembershipData(actual_response[0]);
          let start_time = moment(
            maxDate + "T" + actual_response[0].attributes.StartTime
          ).format("HH:mm");
          let end_time = moment(
            maxDate + "T" + actual_response[0].attributes.EndTime
          ).format("HH:mm");
          setDefaultTimeRange({ start: start_time, end: end_time });
          setTimeRangeValue({ start: start_time, end: end_time });
        }
      });
    }

    if (values && values.hasOwnProperty('date')) {
      setDateValue(values.date);
    }
  }, []);

  useEffect(() => {
    if (usermembershipData && usermembershipData.hasOwnProperty("attributes")) {
      let selectedDay = moment(datevalue).format("dddd");
      //if (moment(datevalue).diff(start_of_web, "days") >= 0) {
      if (usermembershipData.attributes[selectedDay]) {
        let date = moment().format("yyyy-MM-DD");
        let start_time = moment(
          date + "T" + usermembershipData.attributes.StartTime
        ).format("HH:mm");
        let end_time = moment(
          date + "T" + usermembershipData.attributes.EndTime
        ).format("HH:mm");
        getTimeSlotData("", start_time, end_time);
      } else {
        setAllSlots([]);
      }
      /* } else {
        setAllSlots([]);
      } */
    }
  }, [usermembershipData]);

  useEffect(() => {
    console.log("Time ", timerangevalue);
  }, [timerangevalue]);

  async function getAllTeeTimeData() {
    const qs = require("qs");
    const query = qs.stringify(
      {
        filters: {
          InitialPrice: {
            $notNull: true,
          },
        },
        sort: "InitialPrice",
        pagination: {
          pageSize: 100,
        },
      },
      {
        encodeValuesOnly: true,
      }
    );

    await axios
      .get(process.env.REACT_APP_API_URL + "tee-times/?" + query, {
        headers: { Authorization: process.env.REACT_APP_API_KEY },
      })
      .then((response) => {
        const teetimedata = response.data.data;
        var timeslot = "";
        if (teetimedata != null) {
          const datalength = response.data.data.length;
          let alltimeSlots = [];
          for (var i = 0; i < datalength; i++) {
            var initialprice = response.data.data[i].attributes.InitialPrice;

            if (
              alltimeSlots.filter(
                (single_price) => single_price == initialprice
              ).length === 0
            ) {
              alltimeSlots.push(initialprice);
            }
          }
        }
      })
      .catch((error) => {
        console.log(
          "An error has occured in function getAllTeeTimeData : ",
          error
        );
        setErrorMsg("Some error has occured.");
      });
  }

  async function getTimeSlotData(date = "", start_time = "", end_time = "") {
    setLoadingSlots(true);
    setBookingAllowed(true);
    setAllSlots([]);
    if (moment(maxDateValue).diff(moment(date), "days") == 0) {
      await axios
        .get(process.env.REACT_APP_API_URL + "custom-api", {
          headers: { Authorization: process.env.REACT_APP_API_KEY },
        })
        .then((ts_response) => {
          setLoadingSlots(false);
          setBookingAllowed(ts_response.data.status);
          console.log("Custom API Status", ts_response.data.status);
          if (ts_response.data.status == true) {
            setLoadingSlots(true);
            if (date == "") {
              date = datevalue;
            }
            if (start_time == "") {
              start_time = timerangevalue.start;
            }

            if (date == moment(date).format("YYYY-MM-DD")) {
              if (
                moment(date + " " + start_time)
                  .fromNow()
                  .match(/\d+/)[0] > 0
              ) {
                start_time = moment().format("HH:mm");
              }
            }

            if (end_time == "") {
              end_time = timerangevalue.end;
            }
            const qs = require("qs");
            const query = qs.stringify(
              {
                filters: {
                  Date: {
                    $eq: date,
                  },
                  StartTime: {
                    $gte: start_time + ":00.000",
                  },
                  EndTime: {
                    $lte: end_time + ":00.000",
                  },
                  AvailableSlots: {
                    $eq: "Yes",
                  },
                },
                pagination: {
                  pageSize: 100,
                },
              },
              {
                encodeValuesOnly: true,
              }
            );
            /* console.log(
              moment(maxDateValue).diff(moment(date), "days"),
              moment(maxDateValue),
              moment(date)
            ); */
            if (moment(maxDateValue).diff(moment(date), "days") >= 0) {
              axios
                .get(
                  process.env.REACT_APP_API_URL + "tee-time-slots?" + query,
                  {
                    headers: { Authorization: process.env.REACT_APP_API_KEY },
                  }
                )
                .then((response) => {
                  setAllSlots(response.data.data);
                  setLoadingSlots(false);
                })
                .catch((error) => {
                  console.log("An error has Occured in consuming getTimeSlotData", error);
                  setErrorMsg("Some error has occured.");
                });
            } else {
              setAllSlots([]);
              setLoadingSlots(false);
            }
          }
        })
        .catch((error) => {
          console.log("An error has Occured in getTimeSlotData", error);
          setErrorMsg("Some error has occured.");
        });
    } else {
      setLoadingSlots(true);
      if (date == "") {
        date = datevalue;
      }
      if (start_time == "") {
        start_time = timerangevalue.start;
      }

      if (date == moment(date).format("YYYY-MM-DD")) {
        if (
          moment(date + " " + start_time)
            .fromNow()
            .match(/\d+/)[0] > 0
        ) {
          start_time = moment().format("HH:mm");
        }
      }

      if (end_time == "") {
        end_time = timerangevalue.end;
      }
      const qs = require("qs");
      const query = qs.stringify(
        {
          filters: {
            Date: {
              $eq: date,
            },
            StartTime: {
              $gte: start_time + ":00.000",
            },
            EndTime: {
              $lte: end_time + ":00.000",
            },
            AvailableSlots: {
              $eq: "Yes",
            },
          },
          pagination: {
            pageSize: 100,
          },
        },
        {
          encodeValuesOnly: true,
        }
      );
      if (moment(maxDateValue).diff(moment(date), "days") >= 0) {
        await axios
          .get(process.env.REACT_APP_API_URL + "tee-time-slots?" + query, {
            headers: { Authorization: process.env.REACT_APP_API_KEY },
          })
          .then((response) => {
            setAllSlots(response.data.data);
            setLoadingSlots(false);
          })
          .catch((error) => {
            console.log("An error has Occured in getTimeSlotData. ", error);
            setErrorMsg("Some error has occured.");
          });
      } else {
        setAllSlots([]);
        setLoadingSlots(false);
      }
    }
  }

  function generateClick() {
    var playerbtn = document.getElementsByClassName("playerbtn");
    for (var pb = 0; pb < playerbtn.length; pb++) {
      playerbtn[pb].addEventListener("click", function (e) {
        storePlayerAndRateFun(
          e.target.getAttribute("player"),
          e.target.getAttribute("price"),
          e.target.getAttribute("slotstarttime"),
          e.target.getAttribute("slot_id")
        );
      });
    }
  }

  async function selectPlayers(playerCount, slotDetails) {
    console.log(
      " ON PLAYER SELECTION :: Player Count -" +
      playerCount +
      " Slot Details - " +
      JSON.stringify(slotDetails)
    );


    const u_b_id = localStorage.getItem('u_b_id');
    if (!u_b_id) {
      localStorage.setItem("u_b_id", Math.floor(Date.now() / 1000) + Math.random().toString(36).slice(2));
    }

    let reserve_query = slotDetails.id + "/" + u_b_id;
    let reserve_result = await axios
      .get(process.env.REACT_APP_API_URL + "checkAndReserveSlot/" + reserve_query, {
        headers: { Authorization: process.env.REACT_APP_API_KEY },
      })

    console.log(reserve_result);
    let reservation_result = reserve_result.data.reservation_result;
    if (!reservation_result.success || reservation_result.success == 0) {
      toast.error(reservation_result.message);
      return false;
    }

    //let expiry_time = moment().add(reservation_result.expiry_secs, 'seconds').format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]")

    const slot_starttime = moment(
      slotDetails.attributes.StartTime,
      "HH:mm:ss.SSS"
    ).format("HH:mm");
    if (usermembershipData.attributes.IsDefault) {
      storePlayerAndRateFun(
        playerCount,
        slotDetails.attributes.Price * playerCount,
        slot_starttime,
        slotDetails.id,
        slotDetails.attributes.MaximumNumberofPlayers,
        reservation_result.expiry_secs
      );
    } else {
      storePlayerAndRateFun(
        playerCount,
        0,
        slot_starttime,
        slotDetails.id,
        slotDetails.attributes.MaximumNumberofPlayers,
        reservation_result.expiry_secs
      );
    }
  }


  return (
    <div className="w-full px-[10px] md:px-[50px] py-4 bg-[#fafafa] overflow-hidden">
      <div className="bg-white rounded shadow-xl">
        <div className="w-full flex flex-col">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 px-[20px] lg:px-[50px] py-[20px] lg:py-[30px]">
            <div className="h-fit w-full bg-white shadow-md border border-gray-200 rounded-lg px-[20px] py-[10px] lg:px-[40px] lg:py-[50px]">
              <div className="grid grid-cols-1 gap-6">
                <label className="block">
                  <span className="text-[#707070] font-medium text-[18px]">
                    Select Date
                  </span>
                  {/* <input
                    type="date"
                    className="mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                    id="pick_date"
                    value={datevalue}
                    min={minDateValue}
                    max={maxDateValue}
                    onChange={(e) => dateChangeHandler(e.target.value)}
                  /> */}
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={moment(datevalue).toDate()}
                    minDate={moment(minDateValue).toDate()}
                    maxDate={moment(maxDateValue).toDate()}
                    onChange={(update) => {
                      dateChangeHandler(update);
                    }}
                    customInput={<DateCustomInput ref={ref} />}
                  />
                </label>
                <label className="block">
                  <span className="text-[#707070] font-medium text-[18px]">
                    Time Range
                  </span>
                  <div>
                    <TimeRangeSlider
                      disabled={false}
                      format={24}
                      maxValue={defaultTimeRange.end}
                      minValue={defaultTimeRange.start}
                      name={"time_range"}
                      onChange={timeChangeHandler}
                      onChangeComplete={changeCompleteHandler}
                      step={10}
                      value={timerangevalue}
                    />
                  </div>
                </label>
                <label className="block text-center">
                  <button
                    type="button"
                    className="bg-[#EBEBEB] rounded-md py-2 px-[25px] text-[#4E4E4E] text-[18px] font-medium"
                  >
                    <span id="start_time">{timerangevalue.start}</span> to{" "}
                    <span id="end_time">{timerangevalue.end}</span>
                  </button>
                </label>
                <label className="block">
                  <p className="font-[14px] pt-[5px] text-[#ff0000] text-center">
                    {errormsg}
                  </p>
                </label>
              </div>
            </div>
            {loadingSlots && (
              <div className="text-center">
                <svg
                  className="animate-spin align-text-bottom inline-block h-[24px] w-[24px] mr-2 text-blue"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                <h2 className="inline-block text-[24px] text-[#4E4E4E] font-semibold text-center">
                  Loading slots...
                </h2>
              </div>
            )}
            {!loadingSlots && (
              <div
                className="w-full bg-white shadow-md border border-gray-200 rounded-lg px-[20px] py-[10px] lg:px-[20px] lg:py-[30px] place-items-center"
                id="main_timming_slot_div"
              >
                {bookingAllowed == false && (
                  <h2 className="text-[24px] text-[#4E4E4E] font-semibold text-center">
                    Sorry, booking is not allowed between 12:00am - 08:00am
                  </h2>
                )}
                {bookingAllowed == true && allSlots.length === 0 && (
                  <h2 className="text-[24px] text-[#4E4E4E] font-semibold text-center">
                    Sorry, slots are not available for{" "}
                    {moment(datevalue).format("dddd")}
                  </h2>
                )}
                <div className="grid grid-cols-3 md:grid-cols-5 gap-2 place-items-center mb-6 price_timming">
                  {allSlots.map((single_slot, idx) => {
                    var playerRows = [];
                    for (
                      var i = 1;
                      i <=
                      parseInt(single_slot.attributes.MaximumNumberofPlayers);
                      i++
                    ) {
                      const PlayerNo = i;
                      if (usermembershipData.attributes.IsDefault) {
                        playerRows.push(
                          <p
                            key={i}
                            className="w-full text-[#17BA17] bg-[#ffffff] border-[#17BA17] border hover:bg-[#17BA17] hover:text-[#ffffff] rounded py-1 mb-1 playerbtn cursor-pointer"
                            onClick={(e) =>
                              selectPlayers(PlayerNo, single_slot)
                            }
                          >
                            {" "}
                            {i +
                              " Player - \u00A3" +
                              parseInt(single_slot.attributes.Price) * i}{" "}
                          </p>
                        );
                      } else {
                        playerRows.push(
                          <p
                            key={i}
                            className="w-full text-[#17BA17] bg-[#ffffff] border-[#17BA17] border hover:bg-[#17BA17] hover:text-[#ffffff] rounded py-1 mb-1 playerbtn cursor-pointer"
                            onClick={(e) =>
                              selectPlayers(PlayerNo, single_slot)
                            }
                          >
                            {" "}
                            {i + " Player - \u00A30"}{" "}
                          </p>
                        );
                      }
                    }

                    let previousHeading = teeTimeHeading;
                    teeTimeHeading =
                      "TEE TIMES FOR \u00A3" +
                      parseInt(single_slot.attributes.Price);

                    if (previousHeading != teeTimeHeading) {
                      previousSlot = single_slot;
                      return (
                        <div key={idx} className="col-span-full">
                          <h2 className="text-[24px] text-[#4E4E4E] font-semibold text-center mb-3">
                            {teeTimeHeading}
                          </h2>
                        </div>
                      );
                    } else {
                      if (previousSlot.hasOwnProperty("attributes")) {
                        let copyPSlot = previousSlot;
                        var previousPlayerRows = [];
                        for (
                          var i = 1;
                          i <=
                          parseInt(
                            previousSlot.attributes.MaximumNumberofPlayers
                          );
                          i++
                        ) {
                          const PlayerNo = i;
                          if (usermembershipData.attributes.IsDefault) {
                            previousPlayerRows.push(
                              <p
                                key={i}
                                className="w-full text-[#17BA17] bg-[#ffffff] border-[#17BA17] border hover:bg-[#17BA17] hover:text-[#ffffff] rounded py-1 mb-1 playerbtn cursor-pointer"
                                onClick={(e) =>
                                  selectPlayers(PlayerNo, copyPSlot)
                                }
                              >
                                {" "}
                                {i +
                                  " Player - \u00A3" +
                                  parseInt(previousSlot.attributes.Price) *
                                  i}{" "}
                              </p>
                            );
                          } else {
                            previousPlayerRows.push(
                              <p
                                key={i}
                                className="w-full text-[#17BA17] bg-[#ffffff] border-[#17BA17] border hover:bg-[#17BA17] hover:text-[#ffffff] rounded py-1 mb-1 playerbtn cursor-pointer"
                                onClick={(e) =>
                                  selectPlayers(PlayerNo, copyPSlot)
                                }
                              >
                                {" "}
                                {i + " Player - \u00A30"}{" "}
                              </p>
                            );
                          }
                        }
                        previousSlot = {};
                        return (
                          <div
                            key={idx}
                            className="col-span-2 inline-grid grid-cols-2 justify-items-stretch gap-2 price_timming"
                            style={{ display: "contents" }}
                          >
                            <div className="w-full">
                              <div
                                className={
                                  "w-full border border-[#17BA17] text-[#17BA17] lg:text-[20px] sm:text-[12px] font-medium text-center lg:px-4 sm:px-2 py-2 rounded hover:bg-[#17BA17] hover:text-[#ffffff] group relative cursor-pointer"
                                }
                              >
                                {moment(
                                  copyPSlot.attributes.StartTime,
                                  "HH:mm:ss.SSS"
                                ).format("HH:mm")}
                                <div
                                  className={
                                    "invisible group-hover:visible absolute border border-[#A1E4A1] rounded bg-[#A1E4A1] w-[200px] px-1 py-1 z-10"
                                  }
                                >
                                  {previousPlayerRows}
                                </div>
                              </div>
                            </div>
                            <div className="w-full">
                              <div
                                className={
                                  "w-full border border-[#17BA17] text-[#17BA17] lg:text-[20px] sm:text-[12px] font-medium text-center lg:px-4 sm:px-2 py-2 rounded hover:bg-[#17BA17] hover:text-[#ffffff] group relative cursor-pointer"
                                }
                              >
                                {moment(
                                  single_slot.attributes.StartTime,
                                  "HH:mm:ss.SSS"
                                ).format("HH:mm")}
                                <div
                                  className={
                                    "invisible group-hover:visible absolute border border-[#A1E4A1] rounded bg-[#A1E4A1] w-[200px] px-1 py-1 z-10"
                                  }
                                >
                                  {playerRows}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div key={idx} className="w-full">
                            <div
                              className={
                                "w-full border border-[#17BA17] text-[#17BA17] lg:text-[20px] sm:text-[12px] font-medium text-center lg:px-4 sm:px-2 py-2 rounded hover:bg-[#17BA17] hover:text-[#ffffff] group relative cursor-pointer"
                              }
                            >
                              {moment(
                                single_slot.attributes.StartTime,
                                "HH:mm:ss.SSS"
                              ).format("HH:mm")}
                              <div
                                className={
                                  "invisible group-hover:visible absolute border border-[#A1E4A1] rounded bg-[#A1E4A1] w-[200px] px-1 py-1 z-10"
                                }
                              >
                                {playerRows}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    }
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
