import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import StripeCheckout from "react-stripe-checkout";
import Playerreviewcard from "./Player_review_card";
import Modal from "./Modal";
import "../styles/css/Global-Style.css";
import "../styles/css/Review_order.css";
import usersService from "../services/usersService";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./Checkout";
import { FaSpinner } from "react-icons/fa";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

export default function ReviewOrder(props) {
  const [clientSecret, setClientSecret] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const validationSchema = Yup.object().shape({
    termsCondition: Yup.boolean().oneOf(
      [true],
      "Please accept terms and conditions"
    ),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, setError, formState, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  const { prevStep, values, setFormData } = props;
  const [showModal, setShowModal] = useState(false);
  const [formSubmitError, setFormSubmitError] = useState("");
  const [termsconditionvalue, setTermsConditionValue] = useState(false);
  const [offerpromotionvalue, setOfferPromotionValue] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  let now = new Date();
  var todaydate = moment(now).format("yyyy-MM-DD[T]HH:mm:ss.SSS[Z]");
  var todaydateplusthreemonth = moment(todaydate).add(3, "M");

  const backToPersonal = () => {
    console.info("Back to personal info clicked", {
      AllDATA: values,
    });
    prevStep();
  };

  var createbookingentries = values.Player_Count;

  const submitMembership = () => {
    console.info("Submit Membership", {
      AllDATA: values,
    });
    checkUser(values.Player1Email);
  };

  const [userid, setUserId] = useState("");
  const [bookingid, setBookingId] = useState("");

  function checkUser(useremail, token = "") {
    console.info("Check User Called", {
      useremail: useremail,
      token: token,
    });
    usersService
      .getUserDetailsByUsername(useremail)
      .then((response) => {
        if (response.data[0] !== undefined) {
          // If user exist do not create user
          const dataid = response.data[0].id;
          console.info("User Exists", {
            user: dataid,
          });
          setUserId(dataid);
          /* if (values.hasUserMembership === false) {
            createUserMembership(values, dataid);
          } */
          //createbookingentries
          //for (let cbe = 1; cbe <= parseInt(createbookingentries); cbe++) {
          createBookingDetails(dataid, token);
          //}
        } else {
          console.info("Not an existing user");
          console.log("Not an existing user");
          setUserId("");
          createUser(values, token);
        }
      })
      .catch((error) => {
        console.log("Check User error : " + JSON.stringify(error));
        setFormSubmitError("Issue with submitting form.");
        setUserId("");
      });
  }

  function createUser(values, token = "") {
    console.info("Creating a User", {
      token: token,
      values: values,
    });
    var raw = {
      FirstName: values.Player1Firstname,
      LastName: values.Player1Lastname,
      email: values.Player1Email,
      username: values.Player1Email,
      password: values.Player1Password,
      confirmed: "false",
      Telephone: "",
      MobileNumber: values.Player1Telephone,
      Status: "true",
      OptIn: values.OfferPromotionValue,
      blocked: "false",
      UserRole: "Customer",
      membership_catergory: 1,
      golf_courses: 1,
    };

    axios
      .post(process.env.REACT_APP_API_URL + "users/", raw, {
        headers: { Authorization: process.env.REACT_APP_API_KEY },
      })
      .then((response) => {
        console.log("User : " + JSON.stringify(response));
        console.info("User created", {
          response: response,
        });
        var useriddata = "";
        if (response.data.id !== null) {
          useriddata = response.data.id;
        } else {
          useriddata = response.data.data[0].id;
        }
        setUserId(useriddata);
        /*  if (values.hasUserMembership === false) {
           createUserMembership(values, useriddata);
         } */
        //for (let cbe = 1; cbe <= parseInt(createbookingentries); cbe++) {
        createBookingDetails(useriddata, token);
        //}
      })
      .catch((error) => {
        console.log("User error : " + JSON.stringify(error));
        setFormSubmitError("Issue with submitting form.");
        setUserId("");
      });
  }

  /*  function createUserMembership(values, userid) {
     console.info("Creating a membership", {
       user: userid,
       values: values,
     });
     var usermembershipraw = {
       PaymentDate: todaydate,
       Total: "0",
       UserID: parseInt(userid),
       MembershipID: 1,
       golf_courses: 1,
     };
 
     //Total: values.Player_Rate.toString(),
 
     axios
       .post(process.env.REACT_APP_API_URL + "user-memberships/", { data: usermembershipraw }, {
         headers: { Authorization: process.env.REACT_APP_API_KEY },
       })
       .then((response) => {
        
       })
       .catch((error) => {
         console.log("User Membership error : " + JSON.stringify(error));
         setFormSubmitError("Issue with submitting form.");
       });
   } */

  function createBookingDetails(userid, token = "") {
    console.info("Creating Booking", {
      userid: userid,
      token: token,
    });
    var bookingdetailsraw = {
      tee_time_slot: parseInt(values.SelectedSlotId),
      users_permissions_user: parseInt(userid),
      BookingDate: moment(values.date).format("yyyy-MM-DD[T]HH:mm:ss.SSS[Z]"),
      Status: true,
      OtherPlayers: [],
      hasUserMembership: values.hasUserMembership,
    };

    console.log('Test1 ');
    let visitor_data = [];

    try {
      for (let index = 2; index <= parseInt(values.Player_Count); index++) {
        let single_visitor = {};
        if (
          values["Player" + index + "Firstname"] == undefined ||
          values["Player" + index + "Firstname"] == ""
        ) {

          console.log('Test2 ');
          bookingdetailsraw.OtherPlayers.push("Player " + index);
          single_visitor['FirstName'] = "Player " + index;
          single_visitor['LastName'] = "Temp";
          single_visitor['Email'] = "player" + index + "@golfteetime.co.uk";
        } else {

          console.log('Test3 ');
          single_visitor['FirstName'] = values["Player" + index + "Firstname"];
          if (
            values["Player" + index + "Lastname"] == undefined ||
            values["Player" + index + "Lastname"] == ""
          ) {

            console.log('Test4 ');
            bookingdetailsraw.OtherPlayers.push(
              values["Player" + index + "Firstname"]
            );
            single_visitor['LastName'] = "Temp";
          } else {

            console.log('Test5 ');
            bookingdetailsraw.OtherPlayers.push(
              values["Player" + index + "Firstname"] +
              " " +
              values["Player" + index + "Lastname"]
            );
            single_visitor['LastName'] = values["Player" + index + "Lastname"];
          }

          if (values["Player" + index + "Email"] == undefined ||
            values["Player" + index + "Email"] == "") {

            console.log('Test6 ');
            single_visitor['Email'] = "player" + index + "@golfteetime.co.uk";
          } else {

            console.log('Test7 ');
            single_visitor['Email'] = values["Player" + index + "Email"];
          }
        }
        visitor_data.push(single_visitor);
      }
    } catch (error) {
      console.log('foreach error', error);
    }

    console.info("Booking Data", {
      bookingData: bookingdetailsraw,
    });
    const payment_data = {
      payment_type: "Online",
      green_fees_amount: values.Player_Rate,
      total_amount: values.Player_Rate,
      user_id: userid,
      token: token,
    };
    axios
      .post(process.env.REACT_APP_API_URL + "createCompleteBooking/", { booking_data: bookingdetailsraw, visitor_data: visitor_data, payment_data: payment_data }, {
        headers: { Authorization: process.env.REACT_APP_API_KEY },
      }).then((result) => {
        console.log(result);
        setShowModal(true);
        setClientSecret("");
      });
    /*return false;
    axios
      .post(process.env.REACT_APP_API_URL + "tee-time-booking-details/", { data: bookingdetailsraw }, {
        headers: { Authorization: process.env.REACT_APP_API_KEY },
      })
      .then((response) => {
        console.log("Booking details response : ", JSON.stringify(response));
        console.info("Booking Created", {
          response: response,
        });

        var bookingresponse = response;
        var newbookingid = "";
        let bookingIdsArr = [];
        if (typeof bookingresponse.data.id !== "undefined") {
          newbookingid = bookingresponse.data.id;
        } else {
          newbookingid = bookingresponse.data.data.id;
        }
        bookingIdsArr.push(newbookingid);
        setBookingId(newbookingid);

        otherActivity(bookingdetailsraw, bookingIdsArr, userid, token);
      })
      .catch((error) => {
        console.log("Booking details error : " + JSON.stringify(error));
        setFormSubmitError("Issue with submitting form.");
        setBookingId("");
      });*/
  }

  /*   async function otherActivity(
      bookingdetailsraw,
      bookingIdsArr,
      userid,
      token
    ) {
      let promises = [];
      for (let index = 2; index <= parseInt(values.Player_Count); index++) {
        let visitorBookingId = 0;
        if (
          values["Player" + index + "Firstname"] == undefined ||
          values["Player" + index + "Firstname"] == ""
        ) {
          bookingdetailsraw.OtherPlayers.push("Player " + index);
          promises.push(
            Promise.resolve(
              createVisitorComplete(
                "Player " + index,
                "Temp",
                "player" + index + "@golfteetime.co.uk",
                "0",
                "0"
              )
            )
          );
        } else {
          if (
            values["Player" + index + "Lastname"] == undefined ||
            values["Player" + index + "Lastname"] == ""
          ) {
            promises.push(
              Promise.resolve(
                createVisitorComplete(
                  values["Player" + index + "Firstname"],
                  "",
                  values.Player2Email,
                  values.Player2Telephone,
                  "0"
                )
              )
            );
          } else {
            promises.push(
              Promise.resolve(
                createVisitorComplete(
                  values["Player" + index + "Firstname"],
                  values["Player" + index + "Lastname"],
                  values.Player2Email,
                  values.Player2Telephone,
                  "0"
                )
              )
            );
          }
        }
  
        //bookingIdsArr.push(visitorBookingId);
      }
  
      await Promise.all(promises)
        .then((results) => {
          console.log("All promises resolved with ", results);
          let all_ids = bookingIdsArr.concat(results);
          if (!values.isFMember) {
            all_ids = bookingIdsArr;
          }
          updateTeeTimeSlot(all_ids);
          CreatePaymentDetails(
            values.Player_Rate,
            values.Player_Rate,
            all_ids[0],
            parseInt(userid),
            all_ids,
            token
          );
          setShowModal(true);
          setClientSecret("");
        })
        .catch((error) => {
          console.log("Promise error" + JSON.stringify(error)); // rejectReason of any first rejected promise
        });
    } */

  /*   async function createVisitorComplete(
      firstname,
      lastname,
      email,
      mobile,
      bookingid
    ) {
      const visitorId = await createVisitorOnly(
        firstname,
        lastname,
        email,
        mobile,
        bookingid
      );
      console.log("VIsitor Id ", visitorId);
      let res = Promise.resolve(createVisitorBooking(visitorId));
      console.log("VIsitor Booking Id ", JSON.stringify(res));
      return res;
    }
  
    async function createVisitorOnly(
      firstname,
      lastname,
      email,
      mobile,
      bookingid
    ) {
      var visitorraw = {
        FirstName: firstname,
        LastName: lastname,
        Email: email,
        MobileNumber: parseInt(mobile),
        Bookingdetails: parseInt(bookingid),
        publishedAt: todaydate,
      };
  
      console.info("Create Visitor", {
        visitor: visitorraw,
      });
  
     
      return axios
        .post(process.env.REACT_APP_API_URL + "visitor-details/", { data: visitorraw }, {
          headers: { Authorization: process.env.REACT_APP_API_KEY },
        })
        .then((response) => {
          console.log("Visitor response : " + JSON.stringify(response));
          console.info(
            "Visitor reponse",
            JSON.stringify({
              response: response,
            })
          );
  
  
          if (response.data.hasOwnProperty('id')) {
            return response.data.id;
          } else {
            return response.data.data.id;
          }
  
        })
        .catch((error) => {
          console.log("Visitor error : " + JSON.stringify(error));
          setFormSubmitError("Issue with submitting form.");
        });
    }
  
    function createVisitorBooking(visitor_id) {
      var bookingdetailsraw = {
        visitor_detail: visitor_id,
        tee_time_slot: parseInt(values.SelectedSlotId),
        BookingDate: moment(values.date).format("yyyy-MM-DD[T]HH:mm:ss.SSS[Z]"),
        Status: true,
        OtherPlayers: [],
        hasUserMembership: false,
      };
      console.info("Create Visitor Booking", {
        bookingdetails: bookingdetailsraw,
      });
      return axios
        .post(process.env.REACT_APP_API_URL + "tee-time-booking-details/", { data: bookingdetailsraw }, {
          headers: { Authorization: process.env.REACT_APP_API_KEY },
        })
        .then((response) => {
          console.info("Visitor Booking Created", {
            response: response,
          });
          //return response.data.id;
          if (response.data.hasOwnProperty('id')) {
            return response.data.id;
          } else {
            return response.data.data.id;
          }
        });
    } */

  /*   async function CreatePaymentDetails(
      green_fees_amount,
      total_amount,
      booking_id,
      user_id,
      booking_ids,
      token
    ) {
      const rawbody = {
        payment_type: "Online",
        green_fees_amount: green_fees_amount,
        total_amount: total_amount,
        epos: 0,
        booking_id: booking_id,
        tee_time_booking_details: booking_ids,
        user_id: user_id,
        token: token,
      };
  
      console.info("Create Payment", {
        data: rawbody,
      });
  
      axios
        .post(process.env.REACT_APP_API_URL + "payment-details/", { data: rawbody }, {
          headers: { Authorization: process.env.REACT_APP_API_KEY },
        })
        .then((response) => {
          console.info("Payment response", {
            response: response,
          });
          console.log("Payment response : " + JSON.stringify(response));
          setIsLoading(false);
        })
        .catch((error) => {
          console.info("Payment response error ", {
            error: error,
          });
          console.log("Payment error : " + JSON.stringify(error));
          setFormSubmitError("Issue with submitting form.");
          setIsLoading(false);
        });
    }
   */
  /* async function updateTeeTimeSlot(bookingIdsArr) {
    var updateraw = {};
    if (parseInt(values.maxPlayers) == parseInt(values.Player_Count)) {
      updateraw = {
        data: {
          AvailableSlots: "No",
          PlayersBooked: parseInt(values.Player_Count),
          tee_time_booking_details: bookingIdsArr,
        },
      };
    } else {
      updateraw = {
        data: {
          AvailableSlots: "Partial",
          PlayersBooked: parseInt(values.Player_Count),
          tee_time_booking_details: bookingIdsArr,
        },
      };
    }

    console.info("Tee Time Status update", {
      data: updateraw,
    });

    await axios
      .put(
        process.env.REACT_APP_API_URL +
        "tee-time-slots/" +
        parseInt(values.SelectedSlotId),
        updateraw,
        {
          headers: { Authorization: process.env.REACT_APP_API_KEY },
        }
      )
      .then((response) => {
        console.info("Tee Time Status updated", {
          response: response,
        });
        console.log("Tee Time Slot Updated.");
      })
      .catch((error) => {
        console.log(
          "updateTeeTimeSlot method error : " + JSON.stringify(error)
        );
        //setFormSubmitError("Issue with submitting form.");
      });
  } */

  const onToken = (token) => {
    /* console.log(token, token.id);
      if(token.id != "") { */
    let allData = values;

    const payment_data = {
      payment_type: "Online",
      green_fees_amount: values.Player_Rate,
      total_amount: values.Player_Rate,
      token: token,
    };
    axios.post(process.env.REACT_APP_API_URL + "createTempPayment/", { payment_data: payment_data }, {
      headers: { Authorization: process.env.REACT_APP_API_KEY },
    });
    console.info(
      "Payment token - " + token + " other Data - " + JSON.stringify(allData)
    );
    console.log(
      "Payment token - " + token + " other Data - " + JSON.stringify(allData)
    );
    console.log(values);
    checkUser(values.Player1Email, token);
    /* } else {
        setFormSubmitError("Payment failed Please try after sometime.");
      } */
  };

  const triggerError = (result) => {
    console.log("Stripe Error" + JSON.stringify(result));
  };

  const setTermsConditions = (checkedvalue) => {
    //console.log(checkedvalue);
    setTermsConditionValue(checkedvalue);
    values.TermsConditionValue = checkedvalue;
    setFormData(values);
  };

  const setOfferAndPromotion = (checkedvalue) => {
    //console.log(checkedvalue);
    setOfferPromotionValue(checkedvalue);
    values.OfferPromotionValue = checkedvalue;
    setFormData(values);
  };

  const playerReviewDiv = () => {
    const playercard = [];
    for (var i = 2; i <= parseInt(values.Player_Count); i++) {
      playercard.push(
        <Playerreviewcard
          key={i}
          backToPersonal={backToPersonal}
          values={values}
          playerNumber={i}
        />
      );
    }
    return playercard;
  };

  const token = localStorage.getItem("token");
  let userData = localStorage.getItem("userData");
  if (userData) {
    userData = JSON.parse(userData);
  }

  var classnameinprogress = "review_info";

  var selectedslotdate = "";
  if (typeof values.date !== "undefined") {
    selectedslotdate = moment(values.date, "yyyy-MM-DD").format("DD-MM-YYYY");
  }

  const showPaymentPopup = () => {
    setIsLoading(true);
    if (parseInt(values.Player_Rate) !== 0) {
      //+ values.Player_Rate
      axios
        .get(
          process.env.REACT_APP_API_URL +
          "createStripeIntent/" +
          values.Player_Rate +
          "/" +
          values.Player1Email +
          "/" +
          values.Player1Firstname +
          "/" +
          values.Player1Lastname +
          "/" +
          values.SelectedSlotId +
          "/" +
          values.Player_Count +
          "/" +
          values.Player1Telephone,
          {
            headers: { Authorization: process.env.REACT_APP_API_KEY },
          }
        )
        .then((response) => {
          console.log("Stripe Client Secret", response);
          //options.clientSecret = response.data.clientSecret;
          setClientSecret(response.data.clientSecret);
          //setIsLoading(false);
        });
    } else {
      submitMembership();
    }
  };

  const closePaymentPopup = () => {
    setClientSecret("");
    setIsLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="">
      {showModal ? <Modal /> : null}
      <div className="w-full px-[10px] md:px-[50px] py-4 bg-[#fafafa]">
        <div className="w-full bg-white md:shadow-md md:border md:border-gray-200 rounded-lg">
          <div className="">
            <div className="w-full bg-white shadow-md border border-gray-200 rounded-lg px-[10px] py-[10px] md:px-[40px] md:py-[30px]">
              <p className="text-[14pt] md:text-[30px] text-[#4E4E4E] font-semibold px-6">
                Booking Overview
              </p>
              <div className="flex items-center justify-center">
                <div className="bg-white md:shadow-lg md:border md:border-gray-200 rounded-[1rem] md:m-8 p-4 w-full">
                  <div
                    className={
                      parseInt(values.Player_Count) > 1
                        ? "w-full md:flex justify-center md:divide-x divide-gray-200"
                        : "w-full justify-center md:divide-x divide-gray-200"
                    }
                  >
                    <div className="left-card w-full md:px-4">
                      <div className="info-div">
                        <div className="flex items-start mb-4">
                          <p className="text-[14pt] md:text-[24px] text-[#707070] font-semibold">
                            Your information
                          </p>
                          <button
                            className="text-[14pt] md:text-[20px] text-[#0044FF] hover:underline ml-auto dark:text-blue-500"
                            onClick={backToPersonal}
                          >
                            EDIT
                          </button>
                        </div>
                        <div className="grid grid-cols-1 py-4 md:py-8 bg-gray-200 rounded-md block w-full px-2">
                          <div className="grid grid-cols-2 gap-6 md:px-[40px]">
                            <label className="block">
                              <p className="text-[#707070] font-medium text-[12pt] md:text-[18px]">
                                First Name
                              </p>
                              <p className="text-[#707070] font-semibold   mb-2 text-[14pt] md:text-[24px]">
                                {values.Player1Firstname}
                              </p>
                            </label>
                            <label className="block">
                              <p className="text-[#707070] font-medium text-[12pt] md:text-[18px] mb-2">
                                Last Name
                              </p>
                              <p className="text-[#707070] font-semibold   mb-2 text-[14pt] md:text-[24px]">
                                {values.Player1Lastname}
                              </p>
                            </label>
                          </div>
                          <div
                            className={
                              parseInt(values.Player_Count) > 1
                                ? "grid grid-cols-1 gap-6 md:px-[40px]"
                                : "grid grid-cols-2 gap-6 md:px-[40px]"
                            }
                          >
                            <label className="block">
                              <p className="text-[#707070] font-medium text-[12pt] md:text-[18px] mb-2">
                                Email
                              </p>
                              <p className="text-[#707070] font-semibold   mb-2 text-[14pt] md:text-[24px]">
                                {values.Player1Email}
                              </p>
                            </label>
                          </div>
                          <div
                            className={
                              parseInt(values.Player_Count) > 1
                                ? "grid grid-cols-1 gap-6 md:px-[40px]"
                                : "grid grid-cols-2 gap-6 md:px-[40px]"
                            }
                          >
                            <label className="block">
                              <p className="text-[#707070] font-medium text-[12pt] md:text-[18px] mb-2">
                                Telephone
                              </p>
                              <p className="text-[#707070] font-semibold   mb-2 text-[14pt] md:text-[24px]">
                                {values.Player1Telephone}
                              </p>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="booking-div py-6 ">
                        <p className="text-[#707070] font-semibold py-4 text-[14pt] md:text-[24px]">
                          Booking Details
                        </p>
                        <div className="block w-full md:shadow-none shadow-md md:border-none border md:rounded-none rounded-lg md:p-0 p-4">
                          <div className="flex md:items-center justify-start pb-1">
                            <p className="bg-gray-200 text-left text-[#707070] font-medium text-[14pt] md:text-[20px] rounded-none px-1.5 py-1 w-full md:w-[270px]">
                              TOTAL BOOKING FEE
                            </p>
                            <p className="text-left text-[#707070] font-medium text-[14pt] md:text-[20px] px-8 py-1 w-full md:w-[270px]">
                              {"\u00A3" + values.Player_Rate}
                            </p>
                          </div>
                          <div className="flex md:items-center justify-start pb-1">
                            <p className="bg-gray-200 text-left text-[#707070] font-medium text-[14pt] md:text-[20px] rounded-none px-1.5 py-1 w-full md:w-[270px]">
                              BOOKING TIME & DATE
                            </p>
                            <p className="text-left text-[#707070] font-medium text-[14pt] md:text-[20px] px-8 py-1 w-full md:w-[270px]">
                              {values.selectedslot} {selectedslotdate}
                            </p>
                          </div>
                          <div className="flex md:items-center justify-start pb-1 hidden">
                            <p className="bg-gray-200 text-left text-[#707070] font-medium text-[14pt] md:text-[20px] rounded-none px-1.5 py-1 w-full md:w-[270px]">
                              COURSE
                            </p>
                            <p className="text-left text-[#707070] font-medium text-[14pt] md:text-[20px] px-8 py-1 w-full md:w-[270px]">
                              1st TEE
                            </p>
                          </div>
                          <div className="flex md:items-center justify-start pb-1 hidden">
                            <p className="bg-gray-200 text-left text-[#707070] font-medium text-[14pt] md:text-[20px] rounded-none px-1.5 py-1 w-full md:w-[270px]">
                              HOLES
                            </p>
                            <p className="text-left text-[#707070] font-medium text-[14pt] md:text-[20px] px-8 py-1 w-full md:w-[270px]">
                              18
                            </p>
                          </div>
                          <div className="flex md:items-center justify-start pb-1">
                            <p className="bg-gray-200 text-left text-[#707070] font-medium text-[14pt] md:text-[20px] rounded-none px-1.5 py-1 w-full md:w-[270px]">
                              NUMBER OF PLAYERS
                            </p>
                            <p className="text-left text-[#707070] font-medium text-[14pt] md:text-[20px] px-8 py-1 w-full md:w-[270px]">
                              {values.Player_Count}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="right-card w-full md:px-4">
                      {playerReviewDiv()}
                    </div>
                  </div>
                  <div className="grid pb-6">
                    <form
                      id="check-form"
                      name="check-form"
                      method="POST"
                      onSubmit={handleSubmit(showPaymentPopup)}
                    >
                      <label className="inline-flex items-center">
                        <input
                          type="checkbox"
                          className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50"
                          onClick={(e) => setTermsConditions(e.target.checked)}
                          {...register("termsCondition")}
                          name="termsCondition"
                          id="termsCondition"
                        />
                        <span className="ml-4 font-normal text-[#939393]">
                          I have read and accept the Booking Terms and
                          Conditions, especially with regard to refunds.{" "}
                          <a
                            className="text-blue-500 underline"
                            href="https://thorneypark.com/green-fees/"
                            target="_blank"
                          >
                            (Click here for terms and conditions)
                          </a>
                        </span>
                      </label>
                      <div className="ml-8 mb-2 text-left text-red-500 text-xs mt-1 italic first-letter:capitalize">
                        {errors.termsCondition?.message}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="md:flex items-center justify-end text-center p-4">
                <button
                  className="text-[12pt] md:text-[18px] font-medium bg-[#707070] text-white  px-5 py-2.5 rounded md:w-[280px] w-full md:mx-2 my-2 md:my-0"
                  onClick={backToPersonal}
                >
                  Back
                </button>
                {isLoading && (
                  <button
                    type="submit"
                    form="check-form"
                    className="inline-flex justify-center text-[12pt] md:text-[18px] font-medium bg-[#17BA17] text-white px-5 py-2.5 rounded md:w-[280px] w-full md:mx-2 my-2 md:my-0"
                    disabled
                  >
                    <FaSpinner icon="spinner" className="spinner" />{" "}
                    &nbsp;Processing
                  </button>
                )}

                {!isLoading && (
                  <button
                    type="submit"
                    form="check-form"
                    className="inline-flex justify-center text-[12pt] md:text-[18px] font-medium bg-[#17BA17] text-white px-5 py-2.5 rounded md:w-[280px] w-full md:mx-2 my-2 md:my-0"
                  >
                    Confirm Booking
                  </button>
                )}
              </div>
              <div className="place-items-center py-4">
                <p className="text-center text-[#ff0000] text-[14px]">
                  {formSubmitError}
                </p>
              </div>
              {clientSecret && (
                <>
                  <div
                    className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center bg-[#000000ad]"
                    id="modal-id"
                  >
                    <div className="relative w-auto my-6 mx-auto max-w-2xl">
                      <div className=" border-0 relative flex flex-col w-full outline-none focus:outline-none">
                        <Elements
                          stripe={stripePromise}
                          options={{ clientSecret: clientSecret }}
                        >
                          <CheckoutForm
                            triggerAPI={onToken}
                            triggerError={triggerError}
                            closeModal={closePaymentPopup}
                            isProcessingApi={isLoading}
                          />
                        </Elements>
                      </div>
                    </div>
                  </div>
                  <div
                    className="hidden opacity-25 fixed inset-0 z-40 bg-black"
                    id="modal-id-backdrop"
                  ></div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
